import React, { useContext, useEffect, useState } from "react";
import { Drawer, Form } from "antd";
import { InvoiceProps } from "../../interfaces/interfaces";
import { INVOICE_INITIAL_STATE } from "../../utils/data";
import { CustomerInfo } from "../customer";
import QuickbooksContext from "../../context/QuickbooksContext";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";
import { AlertComponent } from "../ui/AlertComponent";

interface Props {
  open: boolean;
  idClient: string;
  idCustomer: string | null;
  onClose: () => void;
}

export const CustomerDrawer = ({
  open,
  idClient,
  idCustomer,
  onClose,
}: Props) => {
  const { DemoModal } = useDemoModal();
  const { getCustomerData, alertData, qbLoading } =
    useContext(QuickbooksContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    idClient &&
      idCustomer &&
      getCustomerData(idCustomer, idClient, () => setOpenDrawer(true));
  }, [idCustomer]);

  return (
    <>
      <Drawer
        title={"Customer Information"}
        width={720}
        onClose={() => {
          onClose();
          setOpenDrawer(false);
        }}
        maskClosable={false}
        open={openDrawer}
        loading={qbLoading}
      >
        {alertData.message && <AlertComponent {...alertData} />}
        <CustomerInfo idClient={idClient} />
      </Drawer>
      <DemoModal />
    </>
  );
};

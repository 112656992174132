import React from "react";
import {
  ClientProps,
  CustomerProps,
  FilterProps,
  InvoiceProps,
  ListComponentProps,
  ProfileProps,
  QuickbooksCompanyProps,
  StatusProps,
  TemplateProps,
} from "../interfaces/interfaces";

interface Props {
  dataLoading: boolean;
  dataSuccess: { status: boolean; message: string };
  dataError: { status: boolean; message: string };
  dataWarning: {
    status: boolean;
    message: string;
    actionLabel?: string;
    action?: () => void;
  };
  statusList: StatusProps[];
  invoiceList: InvoiceProps[];
  customerOptions: ListComponentProps[];
  statusOptions: ListComponentProps[];
  isModalStatusOpen: boolean;
  statusToEdit: StatusProps | null;
  client: null | ClientProps;
  isTourOpen: boolean;
  userAccount: any;
  termOptions: ListComponentProps[];
  customerByClient: CustomerProps[];
  templateList: TemplateProps[];
  handleTour: (status: boolean) => void;
  openCloseModalStatus: (value: boolean) => void;
  handleWarning: (
    status: boolean,
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => void;
  handleSuccess: (status: boolean, message: string) => void;
  selectStatusToEdit: (value: StatusProps | null) => void;
  getData: (
    endpoint:
      | "status"
      | "customer"
      | "invoice"
      | "deposit"
      | "credit"
      | "payment"
      | "journal"
      | "client"
      | "user-account"
      | "profile-info"
      | "term"
      | "template",
    idClient: string,
    search?: string,
    filters?: any
  ) => void;
  getReportData: (
    endpoint: "report" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => void;
  handleCreateData: (
    endpoint: "status" | "template",
    idClient: string,
    data: any,
    next?: () => void
  ) => void;
  handleEditData: ({
    endpoint,
    newData,
    next,
    nextOnFailed,
  }: {
    endpoint: string;
    newData: any;
    next?: () => void;
    nextOnFailed?: (e: any) => void;
  }) => void;
  handleBulkEdit: ({
    endpoint,
    items,
    newData,
    next,
  }: {
    endpoint: string;
    items: string[];
    newData: any;
    next?: () => void;
  }) => void;
  handleDeleteData: (
    endpoint:
      | "status"
      | "invoice"
      | "payment"
      | "deposit"
      | "credit"
      | "journal",

    idClient: string,
    id: string
  ) => void;
  handleBulkDeleteData: (
    endpoint:
      | "status"
      | "invoice"
      | "payment"
      | "deposit"
      | "credit"
      | "journal",
    idClient: string,
    idList: string[]
  ) => void;
  fetchUserAccounts: (idUser: string) => void;
  getQbCompanyInfo: (
    realmId: string,
    next: (data: QuickbooksCompanyProps) => void
  ) => void;
  getCustomersListByClient: ({
    idClient,
    filters,
    search,
    next,
  }: {
    idClient: string;
    filters?: { customer: string[] | null };
    search?: string | null;
    next?: () => void;
  }) => void;
}

const DataContext = React.createContext<Props>({
  dataLoading: false,
  dataSuccess: { status: false, message: "" },
  dataError: { status: false, message: "" },
  dataWarning: {
    status: false,
    message: "",
    actionLabel: "",
    action: () => {},
  },
  statusList: [],
  customerOptions: [],
  statusOptions: [],
  invoiceList: [],
  isModalStatusOpen: false,
  statusToEdit: null,
  client: null,
  isTourOpen: false,
  userAccount: [],
  termOptions: [],
  customerByClient: [],
  templateList: [],
  handleTour: () => {},
  openCloseModalStatus: () => {},
  handleWarning: () => {},
  selectStatusToEdit: () => {},
  getData: () => {},
  getReportData: () => {},
  handleCreateData: () => {},
  handleEditData: () => {},
  handleBulkEdit: () => {},
  handleDeleteData: () => {},
  handleBulkDeleteData: () => {},
  handleSuccess: () => {},
  fetchUserAccounts: () => {},
  getQbCompanyInfo: () => {},
  getCustomersListByClient: () => {},
});

export default DataContext;

import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Form, Row, Divider, List, Card } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { CardTitle, ColumnComponent } from "../../components/ui";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";
import { Base64 } from "js-base64";
import { useDemoModal } from "../../hooks";
import PasswordValidator from "password-validator";

const passwordSchema = new PasswordValidator();

passwordSchema
  .is()
  .min(8)
  .is()
  .max(64)
  .has()
  .not()
  .spaces()
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits();

const passwordErrorsMessages = {
  min: "Password should be at least 8 charachters long",
  max: "Password should be be a maximum of 64 characters long",
  uppercase: "Password should have uppercase characters",
  lowercase: "Password should have lowercase characters",
  digits: "Password should contain digits",
  spaces: "Password should not contain spaces",
  blank: "Password should not be blank",
};

export const ChangePasswordMiddleware = () => {
  const [form] = Form.useForm();
  const { idClient } = useParams();
  const navigate = useNavigate();
  const { DemoModal, openModalDemo } = useDemoModal();
  const { handleEditData, handleWarning } = useContext(DataContext);
  const { profileInfo, isDemoEnv } = useContext(AuthContext);

  const validatePassword = ({
    instance,
    allowToSkip,
  }: {
    instance: any;
    allowToSkip: boolean;
  }) => {
    if (!instance.password) {
      if (allowToSkip) {
        return [];
      } else {
        return [
          {
            key: "password",
            keyword: "missing",
            message: passwordErrorsMessages["blank"],
          },
        ];
      }
    }

    const passwordErrors = passwordSchema.validate(instance.password, {
      list: true,
    });

    if (Array.isArray(passwordErrors) && passwordErrors.length) {
      return passwordErrors.map((error) => {
        return {
          key: "password",
          keyword: error,
          message:
            passwordErrorsMessages[
              error as keyof typeof passwordErrorsMessages
            ],
        };
      });
    } else {
      return [];
    }
  };

  const onFinish = (values: any) => {
    if (isDemoEnv) {
      openModalDemo();
    } else {
      if (values.currentPassword === values.password1) {
        handleWarning(
          true,
          "New password must be different from the current password"
        );
        return;
      }
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d~!@#$%^&*()\-_=+\[\]{}|;:'",.<>]{8,64}$/;
      if (!passwordRegex.test(values.password1)) {
        handleWarning(
          true,
          "Password must be between 8 and 64 characters long, include both uppercase and lowercase letters, contain digits, and must not have spaces or be left blank."
        );
        return;
      }
      const currentPasswordLoop1 = Base64.encode(values.currentPassword);
      const currentPassword = Base64.encode(currentPasswordLoop1);
      const newPasswordLoop1 = Base64.encode(values.password1);
      const newPassword = Base64.encode(newPasswordLoop1);
      profileInfo &&
        handleEditData({
          endpoint: `profile-auth/${profileInfo?._id}`,
          newData: {
            currentPassword,
            newPassword,
          },
          next: () => navigate(`/dashboard/${idClient}`),
          nextOnFailed: (e: any) => console.log(e),
        });
    }
  };

  return (
    <div className="general-view">
      <ColumnComponent>
        <CardTitle title="Change Password" />
      </ColumnComponent>
      <Row
        justify="center"
        style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
      >
        <ColumnComponent md={11} lg={11} xl={11}>
          <Card title={""} hoverable={false} type="inner">
            <div style={{ textAlign: "justify" }}>
              Your new password must be between 8 and 64 characters long,
              include both uppercase and lowercase letters, contain digits, must
              not have spaces or be left blank, must not contain sequential or
              repetitive characters. New password should contain:
            </div>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  title={
                    "Letters [required]: a, b, c, d, e, f, g, … x, y, z, A, B, C, D, E, F, G, … X, Y, Z"
                  }
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  title={"Numbers [required]: 0, 1, 2, 3, 4, 5, 6, 7, 8, and 9"}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  title={
                    "Symbols [optional]: ~, !, @, #, $, %, ^, &, *, (, ), -, _, =, +, [, {, ], }, , |. ;, :, ‘, “, ,, ., <, >"
                  }
                />
              </List.Item>
            </List>
          </Card>
        </ColumnComponent>

        <ColumnComponent md={2} lg={2} xl={2}>
          <Divider type="vertical" />
        </ColumnComponent>

        <ColumnComponent md={11} lg={11} xl={11}>
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item
              label="Current  password"
              name="currentPassword"
              className="collections-form__form-item"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                placeholder="Enter current  password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              label="New password"
              className="collections-form__form-item"
              name="password1"
              validateTrigger="onBlur"
              rules={[
                { required: true, message: "Please input your password" },
                ({}) => ({
                  validator(_, value) {
                    const resultValidation = validatePassword({
                      instance: { password: value },
                      allowToSkip: false,
                    });
                    if (resultValidation && resultValidation.length === 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        resultValidation.map((e) => e.message).join(", ")
                      )
                    );
                  },
                }),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("currentPassword") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "New password must be different from the current password"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="New password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="password2"
              validateTrigger="onBlur"
              label="Repeat new password"
              className="collections-form__form-item"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password1") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Repeat new password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  type="default"
                  onClick={() => navigate(`/dashboard/${idClient}`)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "20px" }}
                >
                  Save Changes
                </Button>
              </div>
            </Form.Item>
          </Form>
        </ColumnComponent>
      </Row>
      <DemoModal />
    </div>
  );
};

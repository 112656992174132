import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Layout, Typography, message } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Loading, Logo } from "../../components/ui";
import { companyName } from "../../utils/company/details";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
const { Link } = Typography;

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const { authError, authSuccess, dataWarning, authLoading, handleWarning } =
    useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [isVisible, setIsVisible] = useState(true);
  const hasSuccessed = useRef(false);
  const hasFailed = useRef(false);
  const hasWarning = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      hasSuccessed.current = false;
    }, 3000);
  }, [hasSuccessed]);

  useEffect(() => {
    setTimeout(() => {
      hasFailed.current = false;
    }, 3000);
  }, [hasFailed]);

  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (authSuccess.status) {
      success(authSuccess.message);
      hasSuccessed.current = true;
    }
  }, [authSuccess.status]);

  useEffect(() => {
    if (authError.status) {
      error(authError.message);
      hasFailed.current = true;
    }
  }, [authError]);

  useEffect(() => {
    if (dataWarning.status) {
      hasWarning.current = true;
      warning(dataWarning.message, dataWarning.actionLabel, dataWarning.action);
    }
  }, [dataWarning]);

  const success = (message: string) => {
    messageApi.success({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.error({
      type: "error",
      content: message,
    });
  };

  const warning = (
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => {
    if (dataWarning.status) {
      messageApi.warning({
        type: "warning",
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>{message}</span>
            <Button
              type="primary"
              onClick={() => {
                action && action();
                messageApi.destroy();
                handleWarning(false, "");
                hasWarning.current = false;
              }}
              style={{ width: "150px", margin: "20px" }}
            >
              {actionLabel ? actionLabel : "OK"}
            </Button>
          </div>
        ),
        duration: 120,
      });
    } else {
      messageApi.destroy();
      handleWarning(false, "");
      hasWarning.current = false;
    }
  };

  return (
    <>
      {authLoading ? (
        <Loading />
      ) : (
        <Layout className="public-layout">
          {contextHolder}
          <Header
            className="public-layout__header"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/about")}
          >
            <Logo mobile={false} />
          </Header>

          {isVisible ? (
            <Content className="public-layout__content">
              <div className="public-layout__content__child">{children}</div>
            </Content>
          ) : (
            <Loading specific />
          )}
          <Footer className="public-layout__footer">
            ©Copyright{" "}
            <a href="https://quattroapps.com" target="_blank" rel="noreferrer">
              {companyName}
            </a>
            , {new Date().getFullYear()} - Read our{" "}
            <Link href="/terms" rel="noreferrer">
              terms
            </Link>{" "}
            and{" "}
            <Link href="/privacy" rel="noreferrer">
              Privacy Policy
            </Link>
          </Footer>
        </Layout>
      )}
    </>
  );
};

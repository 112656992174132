import { Modal } from "antd";
import React, { useState } from "react";

export const useDemoModal = () => {
  const [open, setOpen] = useState(false);

  const DemoModal = () => {
    return (
      <Modal
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        open={open}
        title="You are in Demo Version"
      >
        <p>
          This is a demo version of CollectPRO. Please contact the administrator
          to get the full version
        </p>
        <p>
          <a href="https://quattroapps.com/contact" target="_blank">
            Contact us
          </a>{" "}
          or{" "}
          <a href="https://collectpro.app" target="_blank">
            Visit our website
          </a>{" "}
          for more Info
        </p>
      </Modal>
    );
  };
  return { DemoModal, openModalDemo: () => setOpen(true) };
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Row } from "antd";
import { CardTitle, ColumnComponent } from "../../components/ui";
import AuthContext from "../../context/AuthContext";
import { CLIENT_INITIAL_STATE } from "../../utils/data";
import DataContext from "../../context/DataContext";
import { useParams } from "react-router-dom";
import { useDemoModal } from "../../hooks";
import { Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const HAS_CHANGED_INITIAL_STATE = {
  name: false,
  contact: false,
  address: false,
  phoneNumber: false,
  email: false,
  website: false,
  companyLogo: false,
};

const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const CompanySettings = () => {
  const [form] = Form.useForm();
  const { isDemoEnv } = useContext(AuthContext);
  const { idClient } = useParams();
  const { handleEditData, getData, dataLoading, client } =
    useContext(DataContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [newValues, setNewValues] = useState(CLIENT_INITIAL_STATE);
  const [isEdited, setIsEdited] = useState(false);
  const [dataHasChanged, setDataHasChanged] = useState(
    HAS_CHANGED_INITIAL_STATE
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    idClient && getData("client", idClient);
  }, [idClient]);

  useEffect(() => {
    if (client) {
      setNewValues(client);
      form.setFieldsValue(client);
      client.companyLogo && setFileList([client.companyLogo] as any);
    }
  }, [client]);

  useEffect(() => {
    const sometrue = Object.values(dataHasChanged).some((value) => value);
    setIsEdited(sometrue);
  }, [dataHasChanged]);

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setDataHasChanged((prev) => ({ ...prev, companyLogo: true }));
    setFileList(newFileList);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const editCompany = () => {
    let dataToEdit = {};
    for (let key in dataHasChanged) {
      if ((dataHasChanged as any)[key]) {
        dataToEdit = { ...dataToEdit, [key]: (newValues as any)[key] };
      }
    }
    if (fileList && fileList[0])
      dataToEdit = { ...dataToEdit, companyLogo: fileList[0] };
    Object.keys(dataToEdit).length > 0 &&
      idClient &&
      handleEditData({
        endpoint: `client/${idClient}`,
        newData: dataToEdit,
        next: () => setDataHasChanged(HAS_CHANGED_INITIAL_STATE),
      });
  };

  return (
    <div className="general-view">
      <Row gutter={[24, 0]} align="top" justify="center">
        <ColumnComponent>
          <CardTitle title="Settings" />
        </ColumnComponent>
        <ColumnComponent md={18} xs={18} lg={28} xl={18}>
          <Form
            layout="horizontal"
            {...formItemLayout}
            initialValues={newValues}
            form={form}
            onFinish={isDemoEnv ? () => openModalDemo() : () => editCompany()}
          >
            <Form.Item
              label="Company Name"
              name="name"
              rules={[{ required: true, message: "Company Name Required" }]}
            >
              <Input
                name="Name"
                placeholder="Company Name"
                value={newValues.name}
                onChange={(event) => {
                  setDataHasChanged((prev) => ({ ...prev, name: true }));
                  setNewValues((prev) => {
                    return { ...prev, name: event.target.value };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Main Contact"
              name="contact"
              rules={[{ required: true, message: "Main Contact Required" }]}
            >
              <Input
                name="contact"
                placeholder="Main Contact"
                value={newValues.contact}
                onChange={(event) => {
                  setDataHasChanged((prev) => ({ ...prev, contact: true }));
                  setNewValues((prev) => {
                    return { ...prev, contact: event.target.value };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Physical Address"
              name="address"
              rules={[{ required: true, message: "Address Required" }]}
            >
              <Input
                name="address"
                placeholder="Address"
                value={newValues.address}
                onChange={(event) => {
                  setDataHasChanged((prev) => ({ ...prev, address: true }));
                  setNewValues((prev) => {
                    return { ...prev, address: event.target.value };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: true, message: "Phone Number Required" }]}
            >
              <Input
                name="phoneNumber"
                placeholder="Phone Number"
                value={newValues.phoneNumber}
                onChange={(event) => {
                  setDataHasChanged((prev) => ({ ...prev, phoneNumber: true }));
                  setNewValues((prev) => {
                    return { ...prev, phoneNumber: event.target.value };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Company Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email Address",
                },
                { required: true, message: "Company Email Address Required" },
              ]}
            >
              <Input
                name="email"
                placeholder="Email Address"
                value={newValues.email}
                onChange={(event) => {
                  setDataHasChanged((prev) => ({ ...prev, email: true }));
                  setNewValues((prev) => {
                    return { ...prev, email: event.target.value };
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Website"
              name="website"
              rules={[
                ({}) => ({
                  validator(_, value) {
                    if (!value || isValidUrl(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Not a valid website URL"));
                  },
                }),
              ]}
            >
              <Input
                name="website"
                placeholder="Website"
                value={newValues.website}
                onChange={(event) => {
                  setDataHasChanged((prev) => ({ ...prev, website: true }));
                  setNewValues((prev) => {
                    return { ...prev, website: event.target.value };
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Company Logo" name="companyLogo">
              <ImgCrop rotationSlider>
                <Upload
                  beforeUpload={(file) => {
                    setFileList([...fileList, file]);
                    return false;
                  }}
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length === 0 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isEdited}
                loading={dataLoading}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </ColumnComponent>
      </Row>
      <DemoModal />
    </div>
  );
};

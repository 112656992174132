import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { StatusProps } from "../../interfaces/interfaces";
import { STATUS_INITIAL_LIST } from "../../utils/data";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";

interface Props {
  form: FormInstance<any>;
  values: StatusProps | null;
  idClient: string | undefined;
}

export const StatusForm = ({ form, values, idClient }: Props) => {
  const {
    handleEditData,
    getData,
    handleCreateData,
    openCloseModalStatus,
    selectStatusToEdit,
  } = useContext(DataContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const { isDemoEnv } = useContext(AuthContext);
  const [newValues, setNewValues] = useState<StatusProps>(
    STATUS_INITIAL_LIST[0]
  );
  useEffect(() => {
    if (values) {
      setNewValues(values);
      form.setFieldsValue(values);
    } else {
      setNewValues(STATUS_INITIAL_LIST[0]);
      form.setFieldsValue(STATUS_INITIAL_LIST[0]);
    }
  }, [form, values]);

  const resetForm = () => {
    openCloseModalStatus(false);
    selectStatusToEdit(null);
    setNewValues(STATUS_INITIAL_LIST[0]);
    form.setFieldsValue(STATUS_INITIAL_LIST[0]);
  };

  return (
    <>
      <Row gutter={16} justify="center">
        <Col xs={24} md={16}>
          <Form
            layout="vertical"
            initialValues={newValues}
            form={form}
            onFinish={
              values
                ? () =>
                    isDemoEnv
                      ? openModalDemo()
                      : idClient &&
                        handleEditData({
                          endpoint: `status/${idClient}/${newValues._id}`,
                          newData: newValues,
                          next: () => {
                            resetForm();
                            getData("status", idClient);
                          },
                        })
                : () =>
                    isDemoEnv
                      ? openModalDemo()
                      : idClient &&
                        handleCreateData(
                          "status",
                          idClient,
                          newValues,
                          resetForm
                        )
            }
          >
            <Form.Item
              label="Label"
              name="Label"
              rules={[{ required: true, message: "Label" }]}
            >
              <Input
                name="Label"
                placeholder="Label"
                value={newValues.Label}
                onChange={(event) =>
                  setNewValues((prev) => {
                    return { ...prev, Label: event.target.value };
                  })
                }
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="Description"
              rules={[{ required: true, message: "Description" }]}
            >
              <Input
                name="Description"
                placeholder="Description"
                value={newValues.Description}
                onChange={(event) =>
                  setNewValues((prev) => {
                    return { ...prev, Description: event.target.value };
                  })
                }
              />
            </Form.Item>

            <Form.Item name="Active" wrapperCol={{ offset: 8, span: 16 }}>
              <Checkbox
                checked={newValues.Active}
                onChange={(event) =>
                  setNewValues((prev) => {
                    return { ...prev, Active: event.target.checked };
                  })
                }
              >
                Is Active?
              </Checkbox>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit" style={{ width: "80%" }}>
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <DemoModal />
    </>
  );
};

import { Card, Grid } from "antd";
import React, { ReactNode } from "react";

export const CardComponent = ({
  title,
  extra,
  actions,
  className,
}: {
  title: ReactNode;
  extra?: ReactNode;
  actions?: ReactNode[];
  className?: string;
}) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  let isMobile = xs;
  return (
    <Card
      bordered
      className={className}
      styles={{
        body: isMobile ? { textAlign: "center" } : { display: "none" },
      }}
      title={title}
      extra={isMobile ? [] : extra}
      actions={actions}
    >
      {extra}
    </Card>
  );
};

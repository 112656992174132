import dayjs, { Dayjs } from "dayjs";

export const getDaysDiffNewAccount = (firstDate: Dayjs) => {
  let today = dayjs();
  return 14 - today.diff(firstDate, "days");
};

export const validateEmail = (email: string) => {
  return !!email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateEmailsString = (emails: string) => {
  if (emails.includes(",")) {
    const emailArray = emails.split(",");
    return emailArray.every((email) => validateEmail(email));
  } else {
    return validateEmail(emails);
  }
};

import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DataContext from "../../context/DataContext";
import { Button, Card } from "antd";
import { UserAccountProps } from "../../interfaces/interfaces";
import QuickbooksContext from "../../context/QuickbooksContext";
import AuthContext from "../../context/AuthContext";
import { Loading } from "../../components/ui";

export const DisconnectMiddleware = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { idUser } = useParams();
  const { fetchUserAccounts, handleEditData, userAccount } =
    useContext(DataContext);
  const { qbLoading } = useContext(QuickbooksContext);
  const { onLogout, handleChangeClientSelected } = useContext(AuthContext);
  const isExecuting = useRef(false);
  useEffect(() => {
    if (idUser) {
      fetchUserAccounts(idUser);
    } else {
      onLogout(() => navigate("/login"));
    }
  }, [idUser]);

  useEffect(() => {
    if (userAccount.length === 1) {
      if (isExecuting.current) return;
      isExecuting.current = true;
      handleEditData({
        endpoint: `client/${userAccount[0]._id}`,
        newData: { quickbooksStatus: { connected: false } },
        next: () => {
          handleChangeClientSelected(userAccount[0]._id, true);
          navigate(
            `/quickbooks/${userAccount[0]._id}?message=WW91ciBBY2NvdW50IGhhcyBiZWVuIGRpc2Nvbm5lY3RlZCBmcm9tIFF1aWNrQm9va3M=`
          );
        },
      });
    }
  }, [userAccount]);

  return (
    <>
      {qbLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          {userAccount.length > 0 ? (
            <>
              {userAccount.map((item: UserAccountProps, index: number) => (
                <Card
                  key={index}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "20px",
                    borderColor: "#17549a",
                  }}
                  bordered
                  extra={[
                    <Button
                      type="link"
                      disabled={!item.quickbooksStatus.connected}
                      onClick={() => {
                        handleEditData({
                          endpoint: `client/${item._id}`,
                          newData: { quickbooksStatus: { connected: false } },
                          next: () => {
                            handleChangeClientSelected(item._id, true);
                            navigate(
                              `/quickbooks/${item._id}?message=WW91ciBBY2NvdW50IGhhcyBiZWVuIGRpc2Nvbm5lY3RlZCBmcm9tIFF1aWNrQm9va3M=`
                            );
                          },
                        });
                      }}
                    >
                      Disconnect from QuickBooks
                    </Button>,
                  ]}
                  title={item.name}
                ></Card>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

import React, { useContext, useEffect, useRef } from "react";
import { Card, Popconfirm, Row, Typography } from "antd";
import { CardTitle, ColumnComponent } from "../../components/ui";
import QuickbooksContext from "../../context/QuickbooksContext";
import { ButtonQuickbooksConnect } from "../../components/quickbooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";
import { Base64 } from "js-base64";

export const QuickbooksSettingsClient = () => {
  const { idClient } = useParams();
  const navigate = useNavigate();
  const { DemoModal, openModalDemo } = useDemoModal();
  const { Title, Paragraph, Link } = Typography;
  const { handleRevokeToken, qbLoading } = useContext(QuickbooksContext);
  const { getData, client, handleWarning, dataLoading, handleSuccess } =
    useContext(DataContext);
  const { handleLoginQuickbooks, isDemoEnv } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const refWarning = useRef(false);

  useEffect(() => {
    idClient && getData("client", idClient);
  }, [idClient]);

  useEffect(() => {
    if (refWarning.current) return;
    refWarning.current = true;
    if (searchParams.get("message")) {
      const message = searchParams.get("message")?.toString();
      if (message) {
        if (
          message ===
          "WW91ciBBY2NvdW50IGhhcyBiZWVuIGRpc2Nvbm5lY3RlZCBmcm9tIFF1aWNrQm9va3M="
        ) {
          handleSuccess(false, "");
          const messageDecoded = Base64.decode(message);
          handleWarning(true, messageDecoded, "Ok", () => {});
          idClient && navigate(`/quickbooks/${idClient}`);
          refWarning.current = false;
        } else {
          const messageDecoded = Base64.decode(message);
          handleWarning(true, messageDecoded);
          navigate("/dashboard");
          refWarning.current = false;
        }
      }
    }
  }, []);

  return (
    <div className="general-view">
      <Row gutter={[24, 0]} align="top">
        <ColumnComponent>
          <CardTitle title="QuickBooks Settings" />
        </ColumnComponent>
      </Row>
      <Row gutter={[24, 0]} align="top">
        {dataLoading ? (
          <ColumnComponent>
            <Card loading={true} />
          </ColumnComponent>
        ) : (
          <>
            {client?.quickbooksStatus.connected ? (
              <>
                <ColumnComponent>
                  <Card
                    loading={qbLoading || dataLoading}
                    hoverable={true}
                    title=" Disconnect Your QuickBooks from CollectPRO"
                  >
                    <Paragraph>
                      If you need to disconnect our app from QuickBooks, please
                      follow the steps below. Disconnecting will revoke our
                      app's access and refresh tokens automatically.
                    </Paragraph>
                    <Title level={5}>Steps to Disconnect</Title>
                    <ol>
                      <li>
                        Disconnect QuickBooks: Click on{" "}
                        <Popconfirm
                          title="Yes, Disconnect"
                          onConfirm={() => {
                            if (isDemoEnv) {
                              openModalDemo();
                            } else {
                              idClient &&
                                handleRevokeToken(idClient, () => navigate(0));
                            }
                          }}
                        >
                          <Link>Disconnect from QuickBooks</Link>
                        </Popconfirm>
                      </li>
                      <li>
                        Confirm Disconnection: You will be prompted to confirm
                        that you want to disconnect. Click "Yes, Disconnect" to
                        proceed.
                      </li>
                    </ol>
                    <Paragraph>
                      By disconnecting, our app will no longer have access to
                      your QuickBooks data, and any syncing will stop
                      immediately.
                    </Paragraph>
                  </Card>
                </ColumnComponent>
                <ColumnComponent>
                  <Card hoverable={true} title="What Happens Next?">
                    <ul>
                      <li>
                        Revoked Access: Our app will automatically revoke its
                        access and refresh tokens for your QuickBooks account.
                      </li>
                      <li>
                        Data Synchronization: All data synchronization between
                        our app and QuickBooks will cease.
                      </li>
                      <li>
                        Feature Availability: Certain features of our app that
                        rely on QuickBooks data will no longer be available.
                      </li>
                    </ul>
                    <Title level={5}>How to Reconnect</Title>
                    <Paragraph>
                      If you decide to reconnect our app to QuickBooks, you can
                      do so easily by following these steps:
                    </Paragraph>
                    <ol>
                      <li>
                        Navigate to CollectPRO login or QuickBooks settings.
                      </li>
                      <li>
                        Connect to QuickBooks: Click on the "Connect to
                        QuickBooks" button.
                      </li>
                      <li>
                        Authorize Access: You will be redirected to QuickBooks
                        to authorize access. Follow the prompts to grant our app
                        the necessary permissions.
                      </li>
                    </ol>
                    <Paragraph>
                      Once reconnected, our app will resume syncing with your
                      QuickBooks data, and all related features will be
                      reactivated.
                    </Paragraph>
                  </Card>
                </ColumnComponent>
              </>
            ) : (
              <ColumnComponent>
                <Card
                  title="Simplify and Automate Your Invoice Collections Process"
                  hoverable
                  loading={qbLoading}
                  actions={[
                    <ButtonQuickbooksConnect
                      onClick={() => {
                        if (isDemoEnv) {
                          openModalDemo();
                        } else {
                          handleLoginQuickbooks();
                        }
                      }}
                    />,
                  ]}
                >
                  <Paragraph>
                    CollectPRO offers a centralized platform designed to
                    streamline, prioritize, and automate the collections process
                    for invoices created in QuickBooks. Our app integrates
                    seamlessly with QuickBooks, providing you with a powerful
                    tool to manage your receivables more efficiently.
                  </Paragraph>
                  <Title level={4}>How It Works</Title>
                  <ul>
                    <li>
                      Connect to QuickBooks: Link your QuickBooks account to
                      CollectPRO with a few simple steps.
                    </li>
                    <li>
                      Import Invoices: Automatically import all your outstanding
                      invoices from QuickBooks.
                    </li>
                    <li>
                      Monitor and Manage: Use our centralized dashboard to
                      track, prioritize, and manage your collections process.
                    </li>
                  </ul>
                </Card>
              </ColumnComponent>
            )}
          </>
        )}

        <ColumnComponent>
          <Card title="Need Help?" hoverable>
            <Paragraph>
              If you encounter any issues or need assistance with disconnecting
              or reconnecting our app, please contact our support team:
            </Paragraph>
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:info@quattroapps.com">info@quattroapps.com</a>
              </li>
              <li>
                Visit our{" "}
                <a href="https://support.quattroapps.app" target="_blank">
                  Support Page
                </a>{" "}
                for assistance.
              </li>
            </ul>
            <Paragraph>
              We value your feedback! If you have any comments or suggestions on
              how we can improve our services, please let us know.
            </Paragraph>
            <Paragraph>
              Thank you for using our app. We hope to continue serving you!
            </Paragraph>
          </Card>
        </ColumnComponent>
      </Row>
      <DemoModal />
    </div>
  );
};

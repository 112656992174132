import {
  Button,
  Card,
  Flex,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Tooltip,
  Tour,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardComponent, CardTitle, ColumnComponent } from "../../components/ui";
import QuickbooksContext from "../../context/QuickbooksContext";
import Table from "antd/es/table";
import { getSummaryColumns } from "../../utils/colums/summaryColumns";
import { InvoiceDrawer } from "../../components/collections/InvoiceDrawer";
import dayjs from "dayjs";
import DataContext from "../../context/DataContext";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import AuthContext from "../../context/AuthContext";
import {
  DownloadOutlined,
  MailOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { MultipleSelect, SearchInput } from "../../components/functional";
import { exportSummaryFile } from "../../utils/func/ExportFile";
import { ModalCollectionsBulkUpdate } from "../../components/collections";
import { USDollar } from "../../utils/func/currency";
import { SummaryDataProps, SummaryProps } from "../../interfaces/interfaces";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue } from "antd/es/table/interface";
import { TableProps, TourProps } from "antd/lib";
import { useDemoModal } from "../../hooks";
const { Text } = Typography;

type OnChange = NonNullable<TableProps<SummaryProps>["onChange"]>;

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const SummaryClient = () => {
  const { idClient } = useParams();
  const { clientInfo, isDemoEnv, profileInfo, role } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const {
    isInvoiceDrawerOpen,
    summaryData,
    invoiceListSelected,
    invoiceSelected,
    indexInvoiceSelected,
    selectedRowsToEdit,
    qbLoading,
    handleCancelSelectInvoiceList,
    getData,
    handleSelectInvoiceList,
    handleSelectInvoice,
    updateSelectedInvoice,
    onChangeSelectedRowsToEdit,
    handleBulkEdit,
    handleGetQbData,
    handleChangeFilters,
    onChangeSearchQuery,
    onDemandReminder,
  } = useContext(QuickbooksContext);
  const {
    getData: getListData,
    statusList,
    statusOptions,
    customerOptions,
    isTourOpen,
    handleTour,
    handleSuccess,
  } = useContext(DataContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [asOfDate, setAsOfDate] = useState(dayjs());
  const [difference, setDifference] = useState({
    current: 0,
    total_1_30: 0,
    total_31_60: 0,
    total_61_90: 0,
    total_90: 0,
    gran_total: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState(<></>);
  const [dataTableSize] = useState<number>(100);
  const [summaryList, setSummaryList] = useState<SummaryProps[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const [customerSelected, setCustomerSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasIgnored, setHasIgnored] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const screenRef = useRef(null);

  useEffect(() => {
    if (idClient) {
      getData({ endpoint: "summary", idClient });
      getListData("status", idClient);
      getListData("customer", idClient);
    }
  }, [idClient]);

  useEffect(() => {
    onChangeSearchQuery("");
    setSearchQuery("");
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-summary");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-summary", "true");
      }
    }
  }, []);

  useEffect(() => {
    const defaultSortKey = profileInfo?.preferences?.collections?.summary
      ?.defaultSortColumnKey || { name: "Total", order: "ascend" };
    setSortedInfo({
      columnKey: defaultSortKey.name,
      order: defaultSortKey.order as "ascend" | "descend",
    });
  }, [profileInfo]);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: dataTableSize,
        total: summaryData.data.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
    setSummaryList(summaryData.data);
    customerSelected.length === 0 &&
      statusSelected.length === 0 &&
      searchQuery === "" &&
      !hasIgnored &&
      getDifferences(summaryData);
  }, [summaryData]);

  const rowSelectionToEdit = {
    selectedRowKeys: selectedRowsToEdit,
    onChange: onChangeSelectedRowsToEdit,
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  const getDifferences = (summaryData: SummaryDataProps) => {
    const totals = sessionStorage.getItem("totals")
      ? JSON.parse(sessionStorage.getItem("totals") || "")
      : null;
    if (totals && totals.clientId === idClient) {
      let newDiff = {
        current: totals.current - summaryData.total.current,
        total_1_30: Math.round(totals.total_1_30 - summaryData.total["1-30"]),
        total_31_60: Math.round(
          totals.total_31_60 - summaryData.total["31-60"]
        ),
        total_61_90: Math.round(
          totals.total_61_90 - summaryData.total["61-90"]
        ),
        total_90: Math.round(totals.total_90 - summaryData.total[">90"]),
        gran_total: Math.round(totals.gran_total - summaryData.total.total),
      };
      let listOfTotals: any[] = [];
      Object.entries(newDiff).forEach(([key, value], index) => {
        if (value > 0 || value < 0) {
          listOfTotals.push({
            label:
              key === "current"
                ? "Current A/R"
                : key === "total_1_30"
                ? "1-30 A/R"
                : key === "total_31_60"
                ? "31-60 A/R"
                : key === "total_61_90"
                ? "61-90 A/R"
                : key === "total_90"
                ? ">90 A/R"
                : "Total",
            value: USDollar.format(value),
            index,
          });
        }
      });
      setDifference(newDiff);
      if (listOfTotals.length > 0) {
        setModalBody(
          <>
            Discrepancy identified,
            <span>
              {" "}
              Please review the following values, as they differ from those in
              QuickBooks.
            </span>
            <ul>
              {listOfTotals.map((line: any) => (
                <React.Fragment key={line?.index}>
                  {line?.value && (
                    <div>
                      <strong>{line?.label}: </strong>
                      <span>{line?.value}</span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </>
        );
        setOpenModal(true);
      } else {
        setOpenModal(false);
        localStorage.removeItem("totals");
      }
    }
  };

  const getLastUpdate = () => {
    if (summaryData.lastUpdate && summaryData.lastUpdate?.CollectionsDate)
      return (
        <Text style={{ color: "#FFF" }}>
          Last update on:{" "}
          {dayjs
            .utc(summaryData.lastUpdate.CollectionsDate)
            .format("MM/DD/YYYY HH:mm")}
        </Text>
      );
  };

  const sendMultipleReminder = () => {
    if (isDemoEnv) {
      openModalDemo();
    } else {
      if (clientInfo && selectedRowsToEdit.length > 0) {
        onDemandReminder({
          idClient: clientInfo._id,
          customerList: selectedRowsToEdit as string[],
          next: () => {
            clientInfo &&
              idClient &&
              handleBulkEdit({
                endpoint: "invoice-customer",
                idClient: idClient,
                items: selectedRowsToEdit as string[],
                newStatus: {
                  Status: null,
                  LastContact: dayjs(),
                  NewNote: "Invoices Notification sent",
                },
                next: () =>
                  getData({
                    endpoint: "summary",
                    idClient,
                    filters: {
                      customer:
                        customerSelected.length > 0 ? customerSelected : null,
                      status: statusSelected.length > 0 ? statusSelected : null,
                    },
                  }),
              });
          },
        });
      }
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "Toolbar",
      description:
        "This is the toolbar, where you can find all the actions, filters, and export options.",
      target: () => ref1.current,
    },
    {
      title: "As of Date",
      description: "This is the date of the A/R Aging Summary.",
      target: () => ref2.current,
    },
    {
      title: "Edit Single/Multiple Invoice(s)",
      description:
        "Edit the status, email address, phone number of one or multiple invoices by customer at once.",
      target: () => ref3.current,
    },
    {
      title: "Take screenshot",
      description:
        "Take an screenshot of the A/R Summary and copy it to the clipboard.",
      target: () => ref9.current,
    },
    {
      title: "Send Aging Listing",
      description:
        "Send an email listing all due Invoices to the selected customer(s) with overdue invoices.",
      target: () => ref4.current,
    },
    {
      title: "Export Data",
      description: "Export the A/R Aging Summary data to a CSV file.",
      target: () => ref5.current,
    },
    {
      title: "Filter By Status",
      description: "Filter the A/R Aging Summary by status.",
      target: () => ref6.current,
    },
    {
      title: "Filter By Customer",
      description: "Filter the A/R Aging Summary by customer.",
      target: () => ref7.current,
    },
    {
      title: "A/R Summary",
      description: "This is the A/R Aging Summary by customer.",
      target: () => ref8.current,
    },
  ];

  const hasSelected = selectedRowsToEdit.length > 0;

  const handleScreenshot = async () => {
    if (screenRef.current) {
      setInternalLoading(true);
      handleSuccess(false, "");
      await new Promise((resolve, reject) => {
        resolve(
          setTimeout(() => {
            html2canvas(screenRef.current as any).then((canvas) => {
              canvas.toBlob((blob) => {
                const item = new ClipboardItem({ "image/png": blob } as any);
                navigator.clipboard.write([item]);
              });
            });
            handleSuccess(true, "Screenshot copied to clipboard");
            setInternalLoading(false);
          }, 2000)
        );
      });
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={clientInfo?.name + " - A/R Aging Summary"}
            extra={
              isDemoEnv
                ? []
                : [
                    getLastUpdate(),
                    <div
                      style={{
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#2CA01C",
                          color: "#FFF",
                          borderColor: "#2CA01C",
                        }}
                        size="small"
                        loading={qbLoading}
                        onClick={() =>
                          idClient &&
                          handleGetQbData(idClient, () =>
                            getData({
                              endpoint: "summary",
                              idClient,
                              filters: {
                                customer:
                                  customerSelected.length > 0
                                    ? customerSelected
                                    : null,
                                status:
                                  statusSelected.length > 0
                                    ? statusSelected
                                    : null,
                              },
                            })
                          )
                        }
                      >
                        Refresh From QuickBooks
                      </Button>
                    </div>,
                  ]
            }
          />
        </ColumnComponent>
        <ColumnComponent>
          <div ref={ref1}>
            <CardComponent
              className="summary-client__card"
              title={
                <>
                  A/R Aging Summary as of{" "}
                  <span ref={ref2}>{dayjs(asOfDate).format("MM/DD/YYYY")}</span>
                </>
              }
              extra={
                <SearchInput
                  onSearch={(value: string) => {
                    if (value) {
                      setSearchQuery(value);
                      idClient &&
                        getData({
                          endpoint: "summary",
                          idClient,
                          search: value,
                        });
                    } else {
                      setSearchQuery("");
                      idClient && getData({ endpoint: "summary", idClient });
                    }
                  }}
                />
              }
              actions={[
                <div ref={ref3}>
                  <Tooltip title="Edit" placement="left">
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      disabled={!hasSelected}
                      size="large"
                      onClick={() => setIsEditModalOpen(true)}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref9}>
                  <Tooltip title="Take Screenshot" placement="left">
                    <Button
                      type="link"
                      icon={<PictureOutlined />}
                      size="large"
                      loading={internalLoading}
                      onClick={handleScreenshot}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref4}>
                  <Tooltip title="Send Aging Listing" placement="left">
                    <Popconfirm
                      title="Send Aging Listing?"
                      onConfirm={sendMultipleReminder}
                    >
                      <Button
                        type="link"
                        disabled={!(selectedRowsToEdit.length > 0)}
                        loading={qbLoading}
                        icon={<MailOutlined />}
                        size="large"
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>,
                <div ref={ref5}>
                  <Tooltip
                    title={
                      selectedRowsToEdit.length > 0
                        ? "Export Selected Data"
                        : "Export Data"
                    }
                    placement="left"
                  >
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => {
                        exportSummaryFile(
                          summaryData,
                          selectedRowsToEdit as string[]
                        );
                      }}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref6}>
                  <Tooltip title="Filter By Status">
                    <MultipleSelect
                      placeholder="Status"
                      className="summary-client__card__actions__select"
                      options={statusOptions}
                      onSelect={(values) => {
                        setStatusSelected(values as any);
                        idClient &&
                          handleChangeFilters(
                            "summary",
                            idClient,
                            "status",
                            values
                          );
                      }}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref7}>
                  <Tooltip title="Filter By Customer">
                    <MultipleSelect
                      placeholder="Customer"
                      className="summary-client__card__actions__select"
                      options={customerOptions}
                      onSelect={(values) => {
                        setCustomerSelected(values as any);
                        idClient &&
                          handleChangeFilters(
                            "summary",
                            idClient,
                            "customer",
                            values
                          );
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <div ref={screenRef}>
            {summaryData.data.length === 0 ? (
              <div style={{ minHeight: "25vw" }}>
                <Flex gap="middle" align="center" vertical>
                  <Spin size="large" />
                </Flex>
              </div>
            ) : (
              <Table
                bordered
                columns={getSummaryColumns({
                  onCellClick: handleSelectInvoiceList,
                  sortedInfo,
                  summaryColumns:
                    profileInfo?.preferences?.collections?.summary.columns,
                })}
                onChange={handleChange}
                rowSelection={rowSelectionToEdit}
                dataSource={summaryList}
                className="summary-client__table"
                pagination={tableParams.pagination}
                size="small"
                rowKey={"idClient"}
                scroll={{ y: "70vh", x: "100%" }}
                tableLayout="auto"
                summary={() => (
                  <>
                    {summaryData.total && (
                      <Table.Summary.Row className="table-row">
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <strong>Total</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <span
                            style={{
                              color: difference.current !== 0 ? "red" : "green",
                            }}
                          >
                            {USDollar.format(summaryData.total.current)}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <span
                            style={{
                              color:
                                difference.total_1_30 !== 0 ? "red" : "green",
                            }}
                          >
                            {USDollar.format(summaryData.total["1-30"])}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <span
                            style={{
                              color:
                                difference.total_31_60 !== 0 ? "red" : "green",
                            }}
                          >
                            {USDollar.format(summaryData.total["31-60"])}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <span
                            style={{
                              color:
                                difference.total_61_90 !== 0 ? "red" : "green",
                            }}
                          >
                            {USDollar.format(summaryData.total["61-90"])}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <span
                            style={{
                              color:
                                difference.total_90 !== 0 ? "red" : "green",
                            }}
                          >
                            {USDollar.format(summaryData.total[">90"])}
                          </span>
                        </Table.Summary.Cell>
                        {profileInfo?.preferences?.collections?.summary.columns.includes(
                          "unbilledTime"
                        ) && (
                          <Table.Summary.Cell
                            index={0}
                            className="align-right table-row"
                          >
                            <span>{"-"}</span>
                          </Table.Summary.Cell>
                        )}
                        {profileInfo?.preferences?.collections?.summary.columns.includes(
                          "lastPayment"
                        ) && (
                          <Table.Summary.Cell
                            index={0}
                            className="align-right table-row"
                          >
                            <span>{"-"}</span>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={0}>
                          <span
                            style={{
                              color:
                                difference.gran_total !== 0 ? "red" : "green",
                            }}
                          >
                            {USDollar.format(summaryData.total.total)}
                          </span>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                )}
              />
            )}
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <div style={{ textAlign: "right" }}>
            <Tooltip title="Help">
              <Button
                icon={<QuestionCircleOutlined style={{ color: "#17549a" }} />}
                type="default"
                onClick={() => {
                  handleTour(true);
                }}
              />
            </Tooltip>
          </div>
        </ColumnComponent>
      </Row>

      <InvoiceDrawer
        title={`Collections (${invoiceSelected.TransactionType} ${
          indexInvoiceSelected + 1
        } of ${invoiceListSelected.length})`}
        open={isInvoiceDrawerOpen}
        onClose={handleCancelSelectInvoiceList}
        invoice={invoiceSelected}
        options={statusList}
        idClient={idClient}
        indexInvoiceSelected={indexInvoiceSelected}
        list={invoiceListSelected}
        selectInvoice={(indexSelected) => handleSelectInvoice(indexSelected)}
        onClickNext={
          indexInvoiceSelected < invoiceListSelected.length - 1
            ? () => handleSelectInvoice(indexInvoiceSelected + 1)
            : undefined
        }
        onClickPrevious={
          indexInvoiceSelected !== undefined && indexInvoiceSelected > 0
            ? () => handleSelectInvoice(indexInvoiceSelected - 1)
            : undefined
        }
        onSubmit={(newNote, emailAddress, phoneNumber, newDate) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            idClient &&
              updateSelectedInvoice(
                idClient,
                {
                  newNote,
                  emailAddress,
                  phoneNumber,
                  newDate,
                },
                () =>
                  // getData({
                  //   endpoint: "summary",
                  //   idClient,
                  //   filters: {
                  //     customer:
                  //       customerSelected.length > 0 ? customerSelected : null,
                  //     status: statusSelected.length > 0 ? statusSelected : null,
                  //   },
                  // })
                  {}
              );
          }
        }}
      />
      <ModalCollectionsBulkUpdate
        title="Edit All Invoices for Selected Customer(s)"
        isModalOpen={isEditModalOpen}
        onSubmit={(values) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            clientInfo &&
              idClient &&
              handleBulkEdit({
                endpoint: "invoice-customer",
                idClient: idClient,
                items: selectedRowsToEdit as string[],
                newStatus: values,
                next: () =>
                  getData({
                    endpoint: "summary",
                    idClient,
                    filters: {
                      customer:
                        customerSelected.length > 0 ? customerSelected : null,
                      status: statusSelected.length > 0 ? statusSelected : null,
                    },
                  }),
              });
          }
          setIsEditModalOpen(false);
        }}
        onCancel={() => {
          setIsEditModalOpen(false);
        }}
      />
      <Modal
        open={openModal}
        onOk={() =>
          idClient &&
          handleGetQbData(idClient, () =>
            getData({
              endpoint: "summary",
              idClient,
              filters: {
                customer: customerSelected.length > 0 ? customerSelected : null,
                status: statusSelected.length > 0 ? statusSelected : null,
              },
            })
          )
        }
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpenModal(false);
              sessionStorage.removeItem("totals");
              setDifference({
                current: 0,
                total_1_30: 0,
                total_31_60: 0,
                total_61_90: 0,
                total_90: 0,
                gran_total: 0,
              });
              setHasIgnored(true);
            }}
          >
            Ignore
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() =>
              idClient &&
              handleGetQbData(idClient, () =>
                getData({
                  endpoint: "summary",
                  idClient,
                  filters: {
                    customer:
                      customerSelected.length > 0 ? customerSelected : null,
                    status: statusSelected.length > 0 ? statusSelected : null,
                  },
                })
              )
            }
          >
            Retry
          </Button>,
        ]}
      >
        {modalBody}
      </Modal>
      <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} />
      <DemoModal />
    </>
  );
};

import { Select } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";

interface ListComponentProps {
  label: string;
  value: string;
}

export const MultipleSelect = ({
  placeholder,
  options,
  style,
  onSelect,
  className,
  defaultValue,
}: {
  placeholder: string;
  options: ListComponentProps[];
  style?: CSSProperties;
  onSelect: (values: string[]) => void;
  className?: string;
  defaultValue?: string[];
}) => {
  const [values, setValues] = useState(defaultValue);
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());
  const handleChange = (value: string[]) => {
    onSelect(value);
    setValues(value);
  };

  return (
    <Select
      style={style}
      mode="multiple"
      allowClear
      maxTagCount="responsive"
      value={defaultValue && defaultValue.length !== 0 ? defaultValue : values}
      placeholder={placeholder}
      size="middle"
      className={`modal-filter-report__block ${className}`}
      optionFilterProp="children"
      filterOption={filterOption}
      options={options}
      onChange={(value) => handleChange(value)}
    ></Select>
  );
};

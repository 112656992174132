import React, { useContext, useEffect, useRef, useState } from "react";
import { DemoForm, LoginForm } from "../../components/login";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Grid, Row } from "antd";
import { ColumnComponent, Loading } from "../../components/ui";
import { Base64 } from "js-base64";

export const Login = () => {
  const [searchParams] = useSearchParams();
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const navigate = useNavigate();
  const {
    validateExternalSession,
    handleWarning,
    handleLoading,
    isDemoEnv,
    authLoading,
  } = useContext(AuthContext);
  const warningRef = useRef(false);
  const isValidating = useRef(false);

  useEffect(() => {
    if (isValidating.current) return;
    isValidating.current = true;
    if (searchParams.get("token")) {
      handleLoading(true);
      const token = searchParams.get("token");
      const realmId = searchParams.get("realmId");
      const clientId = searchParams.get("clientId");
      const launch = searchParams.get("launch") ? true : false;
      const revoke = searchParams.get("revoke") ? true : false;
      const redirectTo = searchParams.get("redirectTo");
      token &&
        validateExternalSession({
          token,
          realmId: realmId || undefined,
          clientId: clientId || undefined,
          launch,
          redirectTo: redirectTo || undefined,
          revoke,
        });
      isValidating.current = false;
    }
    if (searchParams.get("message")) {
      const message = searchParams.get("message")?.toString();
      if (message) {
        if (message === "VU5WRVJJRklFRA==") {
          handleWarning(
            true,
            "Your QuickBooks Account is not Verified",
            "Verify Account",
            () => {
              window.open(
                "https://accounts.intuit.com/app/account-manager/security"
              );
              navigate("/login");
            }
          );
        } else {
          const messageDecoded = Base64.decode(message);
          !warningRef.current && handleWarning(true, messageDecoded);
          warningRef.current = true;
          // navigate("/login");
        }
        isValidating.current = false;
      }
    }
    handleLoading(false);
  }, []);

  return (
    <div className="general-view">
      {authLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Row align="middle" justify={xs ? "center" : "end"}>
          <ColumnComponent lg={12} md={12} xl={12}>
            {isDemoEnv ? <DemoForm /> : <LoginForm />}
          </ColumnComponent>
        </Row>
      )}
    </div>
  );
};

import { Button, Modal } from "antd";
import React, { ReactNode, useState } from "react";

export const useAlertPrevent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalAlertPrevent = ({
    type,
    next,
  }: {
    type: "autosave" | "logout";
    next: () => void;
  }) => {
    let title = "";
    let body = <></>;
    switch (type) {
      case "autosave":
        title = "You have unsaved changes";
        body = <>{"Do you want to save them?"}</>;
        break;
      case "logout":
        title = "You need to logout to apply changes";
        body = (
          <>
            <p>Do you want to logout now?</p>
          </>
        );
        break;
      default:
        break;
    }
    return (
      <>
        <Modal
          title={title}
          open={isOpen}
          onOk={() => {
            next();
            setIsOpen(false);
          }}
          onCancel={() => setIsOpen(false)}
        >
          {body}
        </Modal>
      </>
    );
  };
  return { modalAlertPrevent, openAlertPrevent: () => setIsOpen(true) };
};

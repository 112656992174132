import React from "react";
import qbButtonMid from "../../assets/img/quickbooks/C2QB_green_btn_med_hover.svg";
import qbButtonSmall from "../../assets/img/quickbooks/C2QB_green_btn_short_default.svg";
import { Grid } from "antd";

export const ButtonQuickbooksConnect = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const qbButton = xs ? qbButtonSmall : qbButtonMid;
  return (
    <img
      style={{ cursor: "pointer" }}
      src={qbButton}
      alt="Quickbooks Connected Button"
      onClick={onClick}
    />
  );
};

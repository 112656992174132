import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import DataContext from "../../context/DataContext";
import { Base64 } from "js-base64";

export const QuickbooksSettingsPage = () => {
  const navigate = useNavigate();
  const { onLogout, getClientId } = useContext(AuthContext);
  const { handleWarning } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  const refWarning = useRef(false);
  useEffect(() => {
    if (refWarning.current) return;
    if (searchParams.get("message")) {
      refWarning.current = true;
      const message = searchParams.get("message")?.toString();
      if (message) {
        if (
          message ==
          "WW91ciBBY2NvdW50IGhhcyBiZWVuIGRpc2Nvbm5lY3RlZCBmcm9tIFF1aWNrQm9va3M="
        ) {
          const messageDecoded = Base64.decode(message);
          handleWarning(true, messageDecoded, "Ok", () => {
            refWarning.current = false;
          });
        } else {
          const messageDecoded = Base64.decode(message);
          handleWarning(true, messageDecoded, "Ok", () => {
            refWarning.current = false;
            navigate("/dashboard");
          });
        }
      }
    } else {
      const clientId = getClientId();
      if (clientId) {
        navigate(`/dashboard/${clientId}`);
      } else {
        onLogout();
      }
    }
  }, []);
  return <></>;
};

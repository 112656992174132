interface PaginationProps {
  current: string;
  totalPages: string;
  total: string;
}

interface Props {
  endpoint: string;
  options?: RequestInit;
  onSuccess?: (
    res: { success: boolean; data: any; message?: string },
    bufferData?: ArrayBuffer,
    pagination?: PaginationProps | undefined
  ) => void;
  onError?: (error?: any) => void;
  finallyCallback?: () => void;
}
interface PropsRequestFile {
  endpoint: string;
  options?: RequestInit;
  next?: (data: any) => void;
  nextOnFail?: () => void;
}

interface ResponseProps extends Response {
  success: boolean;
}

export const useRequest = () => {
  const handleRequest = async ({
    endpoint,
    options,
    onSuccess,
    onError,
    finallyCallback,
  }: Props): Promise<any> => {
    try {
      const tokenInfo = localStorage.getItem("info");
      const response = (await fetch(
        `${process.env.REACT_APP_DEV_API}/${endpoint}`,
        {
          ...options,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${tokenInfo}`,
            "cache-control": "no-cache, no-store",
          },
          credentials: "include",
        }
      )) as ResponseProps;
      let data = null;
      if (response.ok || response.success) {
        let current = response.headers.get("X-Current-Page");
        let totalPages = response.headers.get("X-Total-Pages");
        let total = response.headers.get("X-Total-Records");
        const data = await response.json();
        let pagination =
          current && totalPages && total
            ? {
                current,
                totalPages,
                total,
              }
            : undefined;
        onSuccess && onSuccess(data, undefined, pagination);
      } else {
        onError && onError(await response.json());
      }
      return data;
    } catch (error) {
      onError && onError(error);
      throw error;
    } finally {
      // finallyCallback && finallyCallback();
    }
  };
  const handleRequestFile = async ({
    endpoint,
    options,
    next,
    nextOnFail,
  }: PropsRequestFile): Promise<any> => {
    try {
      const tokenInfo = localStorage.getItem("info");
      const response = (await fetch(
        `${process.env.REACT_APP_DEV_API}/${endpoint}`,
        {
          ...options,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${tokenInfo}`,
            "cache-control": "no-cache, no-store",
          },
          credentials: "include",
        }
      )) as ResponseProps;
      if (response) {
        const bufferData = await response.arrayBuffer();
        next && next(bufferData);
      } else {
        nextOnFail && nextOnFail();
      }
    } catch (error) {
      nextOnFail && nextOnFail();
      throw error;
    }
  };

  return { handleRequest, handleRequestFile };
};

import { Button, Card, Form, Modal, Popconfirm, Row, Space } from "antd";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import Table, { ColumnsType } from "antd/es/table";
import { StatusProps } from "../../../interfaces/interfaces";
import { CardTitle, ColumnComponent } from "../../../components/ui";
import { StatusForm } from "../../../components/admin";
import AuthContext from "../../../context/AuthContext";
import { useDemoModal } from "../../../hooks";

export const StatusClient = () => {
  const { idClient } = useParams();
  const { isDemoEnv } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [form] = Form.useForm();
  const {
    getData,
    statusList,
    isModalStatusOpen,
    openCloseModalStatus,
    statusToEdit,
    selectStatusToEdit,
    handleDeleteData,
    dataLoading,
  } = useContext(DataContext);

  useEffect(() => {
    if (idClient) {
      getData("status", idClient);
    }
  }, [idClient]);

  const columns: ColumnsType<StatusProps> = [
    {
      title: "Label",
      dataIndex: "Label",
      render: (_, row) => <a>{row.Label}</a>,
      onCell: (record) => {
        return {
          onClick: () => {
            selectStatusToEdit(record);
            openCloseModalStatus(true);
          },
        };
      },
      sorter: {
        compare: (a, b) => a.Label.localeCompare(b.Label),
        multiple: 1,
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      sorter: {
        compare: (a, b) => a.Description.localeCompare(b.Description),
        multiple: 2,
      },
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      className: "center",
      render: (_, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => {
              if (isDemoEnv) {
                openModalDemo();
              } else {
                record._id &&
                  idClient &&
                  handleDeleteData("status", idClient, record._id);
              }
            }}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="general-view">
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title="Status" />
        </ColumnComponent>
        <ColumnComponent>
          <Card
            bordered
            hoverable
            className="card genera-view__component"
            title="Status"
            extra={
              <Button type="primary" onClick={() => openCloseModalStatus(true)}>
                Add Status
              </Button>
            }
          >
            <Table
              bordered
              loading={dataLoading}
              columns={columns}
              dataSource={statusList}
              size="small"
              rowKey={"_id"}
            />
          </Card>
        </ColumnComponent>
      </Row>
      <Modal
        title={statusToEdit ? "Edit" : "Create"}
        open={isModalStatusOpen}
        onOk={() => {
          openCloseModalStatus(false);
          selectStatusToEdit(null);
        }}
        onCancel={() => {
          openCloseModalStatus(false);
          selectStatusToEdit(null);
        }}
        footer={null}
      >
        <StatusForm values={statusToEdit} idClient={idClient} form={form} />
      </Modal>
      <DemoModal />
    </div>
  );
};

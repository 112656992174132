import { Button, Result } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NotFundPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <Result
      status="warning"
      title="This page does not exist"
      extra={
        <Button type="primary" onClick={() => navigate("/login")}>
          Back Home
        </Button>
      }
    />
  );
};

import { ColumnsType } from "antd/es/table";
import { InvoiceProps, SummaryProps } from "../../interfaces/interfaces";
import { Button } from "antd";
import { USDollar } from "../func/currency";
import { TableProps } from "antd/lib";
import dayjs from "dayjs";

type OnChange = NonNullable<TableProps<SummaryProps>["onChange"]>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const getTotalValue = (invoices: InvoiceProps[]) => {
  return invoices.reduce((acc, invoice) => {
    return acc + invoice.Balance;
  }, 0);
};

export const getSummaryColumns = ({
  onCellClick,
  sortedInfo,
  summaryColumns,
}: {
  onCellClick: (type: "client" | "age", record: any) => void;
  sortedInfo: Sorts;
  summaryColumns?: string[];
}) => {
  const columns: ColumnsType<SummaryProps> = [
    {
      title: "Client",
      dataIndex: "_id",
      key: "_id",
      className: "table-row",
      width: "25%",
      render: (_, { _id }) => <Button type="link">{_id.name}</Button>,
      onCell: (record, index) => {
        return {
          onClick: () => onCellClick("client", record),
        };
      },
      sorter: {
        compare: (a, b) => a._id.name.localeCompare(b._id.name),
        multiple: 1,
      },
      sortOrder: sortedInfo.columnKey === "_id" ? sortedInfo.order : null,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Current",
      className: "align-right table-row",
      dataIndex: "current",
      key: "current",
      width: "15%",
      render: (_, row) => (
        <>
          {row.current.length > 0 ? (
            <Button type="link">
              {USDollar.format(getTotalValue(row.current))}
            </Button>
          ) : (
            <Button type="link" disabled>
              0
            </Button>
          )}
        </>
      ),
      sorter: {
        compare: (a, b) => getTotalValue(a.current) - getTotalValue(b.current),
        multiple: 3,
      },
      sortOrder: sortedInfo.columnKey === "current" ? sortedInfo.order : null,
      onCell: (row) => {
        return {
          onClick: () =>
            row.current.length > 0 && onCellClick("age", row.current),
        };
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "1-30",
      dataIndex: "1-30",
      key: "1-30",
      className: "align-right table-row",
      width: "10%",
      render: (_, row) => (
        <>
          {row["1-30"].length > 0 ? (
            <Button type="link">
              {USDollar.format(getTotalValue(row["1-30"]))}
            </Button>
          ) : (
            <Button type="link" disabled>
              0
            </Button>
          )}
        </>
      ),
      sorter: {
        compare: (a, b) => getTotalValue(a["1-30"]) - getTotalValue(b["1-30"]),
        multiple: 4,
      },
      sortOrder: sortedInfo.columnKey === "1-30" ? sortedInfo.order : null,
      onCell: (row) => {
        return {
          onClick: () =>
            row["1-30"].length > 0 && onCellClick("age", row["1-30"]),
        };
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "31-60",
      dataIndex: "31-60",
      key: "31-60",
      className: "align-right table-row",
      width: "10%",
      render: (_, row) => (
        <>
          {row["31-60"].length > 0 ? (
            <Button type="link">
              {USDollar.format(getTotalValue(row["31-60"]))}
            </Button>
          ) : (
            <Button type="link" disabled>
              0
            </Button>
          )}
        </>
      ),
      sorter: {
        compare: (a, b) =>
          getTotalValue(a["31-60"]) - getTotalValue(b["31-60"]),
        multiple: 5,
      },
      sortOrder: sortedInfo.columnKey === "31-60" ? sortedInfo.order : null,
      onCell: (row) => {
        return {
          onClick: () =>
            row["31-60"].length > 0 && onCellClick("age", row["31-60"]),
        };
      },
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: "61-90",
      dataIndex: "61-90",
      key: "61-90",
      className: "align-right table-row",
      width: "10%",
      render: (_, row) => (
        <>
          {row["61-90"].length > 0 ? (
            <Button type="link">
              {USDollar.format(getTotalValue(row["61-90"]))}
            </Button>
          ) : (
            <Button type="link" disabled>
              0
            </Button>
          )}
        </>
      ),
      sorter: {
        compare: (a, b) =>
          getTotalValue(a["61-90"]) - getTotalValue(b["61-90"]),
        multiple: 6,
      },
      sortOrder: sortedInfo.columnKey === "61-90" ? sortedInfo.order : null,
      onCell: (row) => {
        return {
          onClick: () =>
            row["61-90"].length > 0 && onCellClick("age", row["61-90"]),
        };
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: ">90",
      dataIndex: ">90",
      key: ">90",
      className: "align-right table-row",
      width: "10%",
      render: (_, row) => (
        <>
          {row[">90"].length > 0 ? (
            <Button type="link">
              {USDollar.format(getTotalValue(row[">90"]))}
            </Button>
          ) : (
            <Button type="link" disabled>
              0
            </Button>
          )}
        </>
      ),
      sorter: {
        compare: (a, b) => getTotalValue(a[">90"]) - getTotalValue(b[">90"]),
        multiple: 7,
      },
      sortOrder: sortedInfo.columnKey === ">90" ? sortedInfo.order : null,
      onCell: (row) => {
        return {
          onClick: () =>
            row[">90"].length > 0 && onCellClick("age", row[">90"]),
        };
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Unbilled Time",
      dataIndex: "UnbilledTime",
      key: "UnbilledTime",
      className: "align-right table-row",
      hidden: summaryColumns?.includes("unbilledTime") ? false : true,
      render: (_, row) => (
        <div style={{ minWidth: "80px" }}>
          {row.TimeActivity ? row.TimeActivity + " hrs" : "-"}
        </div>
      ),
      sorter: {
        compare: (a, b) => (a.TimeActivity ?? 0) - (b?.TimeActivity ?? 0),
        multiple: 9,
      },
      sortOrder:
        sortedInfo.columnKey === "UnbilledTime" ? sortedInfo.order : null,
    },
    {
      title: "Last Payment",
      dataIndex: "LastPayment",
      key: "LastPayment",
      className: "align-right table-row",
      hidden: summaryColumns?.includes("lastPayment") ? false : true,
      render: (_, row) => (
        <div style={{ minWidth: "80px" }}>
          {row.LastPayment
            ? USDollar.format(row.LastPayment) +
              (row.LastPaymentDate
                ? "  " + dayjs(row.LastPaymentDate).format("MM/DD/YYYY")
                : "")
            : "-"}
        </div>
      ),
      sortOrder:
        sortedInfo.columnKey === "LastPayment" ? sortedInfo.order : null,
      sorter: {
        compare: (a, b) => (a.LastPayment ?? 0) - (b?.LastPayment ?? 0),
        multiple: 9,
      },
    },
    {
      title: "Total",
      className: "align-right table-row",
      width: "10%",
      dataIndex: "total",
      key: "total",
      render: (_, row) => (
        <Button type="link">{USDollar.format(row.total)}</Button>
      ),
      sorter: {
        compare: (a, b) => {
          return a.total - b.total;
        },
        multiple: 2,
      },
      sortOrder: sortedInfo.columnKey === "total" ? sortedInfo.order : null,
      onCell: (record) => {
        return {
          onClick: () => onCellClick("client", record),
        };
      },
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  return columns;
};

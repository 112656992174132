import React, { useContext } from "react";
import { Button, Descriptions, DescriptionsProps, Tooltip } from "antd";
import { InvoiceProps } from "../../interfaces/interfaces";
import dayjs from "dayjs";
import AuthContext from "../../context/AuthContext";
import QuickbooksContext from "../../context/QuickbooksContext";
import { USDollar } from "../../utils/func/currency";

interface Props {
  values: InvoiceProps;
  onClickCustomerTab: () => void;
}

export const InvoiceInfo = ({ values, onClickCustomerTab }: Props) => {
  const { clientInfo } = useContext(AuthContext);
  const { getCustomerData } = useContext(QuickbooksContext);
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: <strong>Customer Name</strong>,
      children: (
        <Tooltip title="Double click to see more details">
          <Button
            type="link"
            onClick={() => {
              clientInfo?._id &&
                getCustomerData(
                  values.CustomerRef.value,
                  clientInfo?._id,
                  onClickCustomerTab
                );
            }}
          >
            {values.CustomerRef?.name}
          </Button>
        </Tooltip>
      ),
      className: "descriptions__label",
    },
    {
      key: "2",
      label: <strong>Total Amount</strong>,
      children: <>{USDollar.format(values.TotalAmt)}</>,
      className: "descriptions__label",
    },
    {
      key: "3",
      label: <strong>Balance</strong>,
      children: <>{USDollar.format(values.Balance)}</>,
      className: "descriptions__label",
    },
    {
      key: "4",
      label: <strong>Num</strong>,
      children: <>{values.DocNumber}</>,
      className: "descriptions__label",
    },
    {
      key: "5",
      label: <strong>Create Date</strong>,
      children: (
        <>
          {values.MetaData
            ? dayjs(values.TxnDate).utc().format("MM/DD/YYYY")
            : ""}
        </>
      ),
      className: "descriptions__label",
    },
    {
      key: "6",
      label: <strong>Due Date</strong>,
      children: (
        <>{values.DueDate ? dayjs(values.DueDate).format("MM/DD/YYYY") : ""}</>
      ),
      className: "descriptions__label",
    },
    {
      key: "7",
      label: <strong>Terms</strong>,

      children: <>{values.SalesTermRef?.name}</>,
      className: "descriptions__label",
    },
    {
      key: "8",
      label: <strong>Contact Info</strong>,
      span: 2,
      children: <>{values.BillEmail?.Address}</>,
      className: "descriptions__label",
    },
  ];

  return (
    <>
      <Descriptions
        title="Invoice Information"
        layout="vertical"
        bordered
        items={items}
        className="descriptions"
      />
    </>
  );
};

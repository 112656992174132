import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { Base64 } from "js-base64";
import DataContext from "../../context/DataContext";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { onLogout, getClientId } = useContext(AuthContext);
  const { handleWarning } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("message")) {
      const message = searchParams.get("message")?.toString();
      if (message) {
        const messageDecoded = Base64.decode(message);
        handleWarning(true, messageDecoded);
        navigate("/dashboard");
      }
    } else {
      const clientId = getClientId();
      if (clientId) {
        navigate(`/dashboard/${clientId}`);
      } else {
        onLogout();
      }
    }
  }, []);
  return <></>;
};

import React, { useContext } from "react";
import { PublicLayout } from "./PublicLayout";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicRoute = ({ children }: Props) => {
  const { validateToken, clientInfo } = useContext(AuthContext);
  if (validateToken() && clientInfo) {
    return <Navigate to={`/dashboard/${clientInfo._id}`} replace />;
  } else {
    return <PublicLayout>{children}</PublicLayout>;
  }
};

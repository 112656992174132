import { Modal, Form, Row, Col, Select, Button, DatePicker } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../context/DataContext";
import { FilterProps } from "../../interfaces/interfaces";
import { FILTERS_INITIAL_STATUS } from "../../utils/data";
const { RangePicker } = DatePicker;

interface Props {
  open: boolean;
  onSubmit: (filters: FilterProps) => void;
  onCancel: () => void;
}

export const ModalFilterReport = ({ open, onSubmit, onCancel }: Props) => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<FilterProps>(FILTERS_INITIAL_STATUS);
  const { customerOptions, statusOptions, dataLoading } =
    useContext(DataContext);

  useEffect(() => {
    setFilters(FILTERS_INITIAL_STATUS);
    form.setFieldsValue(FILTERS_INITIAL_STATUS);
  }, []);

  const handleChange = (key: string, value: string[]) => {
    let query = value.length > 0 ? value : null;
    let newFilters = { ...filters, [key]: query };
    setFilters(newFilters);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());

  return (
    <Modal
      title={"Filters"}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Form
        layout="vertical"
        initialValues={filters}
        form={form}
        onFinish={() => {
          onSubmit(filters);
        }}
      >
        <Row justify="center">
          <Col span={20}>
            <Form.Item
              label="Status"
              className="modal-filter-report__form-item"
            >
              <Select
                mode="multiple"
                allowClear
                className="modal-filter-report__block"
                options={statusOptions}
                placeholder="If none is selected, All Status will be fetched"
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value) => handleChange("status", value)}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Customer"
              className="modal-filter-report__form-item"
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="If none is selected, All Customers will be fetched"
                className="modal-filter-report__block"
                optionFilterProp="children"
                filterOption={filterOption}
                options={customerOptions}
                onChange={(value) => handleChange("customer", value)}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Date Range"
              className="modal-filter-report__form-item"
            >
              <RangePicker
                className="modal-filter-report__block"
                onChange={(_, dates) => handleChange("dates", dates)}
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item className="modal-filter-report__form-item--button">
              <Button
                loading={dataLoading}
                type="primary"
                htmlType="submit"
                className="modal-filter-report__block"
              >
                Filter
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

import React, { useContext, useEffect, useRef } from "react";
import { Loading } from "../../components/ui";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { ClientProps, UserAccountProps } from "../../interfaces/interfaces";

interface TokenProps {
  _id: string;
  name: string;
  user: string;
  role: string;
  realmId: string;
  client: ClientProps;
  clientList: UserAccountProps[];
}

export const RevokeMiddleware = () => {
  const { handleRefreshTokenCollections, compareToken } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isRunning = useRef(false);

  useEffect(() => {
    if (isRunning.current) return;
    isRunning.current = true;
    const token = searchParams.get("token");
    const currentToken = localStorage.getItem("info");
    if (!currentToken) {
      navigate(`/login?revoke=true&token=${token}`);
      isRunning.current = false;
    } else {
      const dataUser: TokenProps | null = decodeToken(currentToken);
      if (token) {
        const isSameUser = compareToken(token);
        if (!isSameUser) {
          localStorage.removeItem("info");
          navigate(`/login?revoke=true&token=${token}`);
        } else {
          handleRefreshTokenCollections(() =>
            navigate(`/disconnect/${dataUser?._id}?token=${currentToken}`)
          );
        }
      } else {
        handleRefreshTokenCollections(() =>
          navigate(`/disconnect/${dataUser?._id}?token=${currentToken}`)
        );
      }
      isRunning.current = false;
    }
  }, []);

  return <Loading />;
};

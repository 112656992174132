import React from "react";
import {
  AlertComponentProps,
  ClientProps,
  CustomerProps,
  DashboardProps,
  DashboardStatusProps,
  DetailProps,
  InvoiceProps,
  SummaryDataProps,
} from "../interfaces/interfaces";
import {
  ALERTCOMPONENT_INITIAL_STATE,
  ALERT_INITIAL_STATE,
  CUSTOMER_INITIAL_STATE,
  DASHBOARD_INITIAL_STATE,
  DETAIL_INITIAL_STATE,
  INVOICE_INITIAL_STATE,
  SUMMARY_DATA_INITIAL_STATE,
} from "../utils/data";
import { Dayjs } from "dayjs";

interface FilterProps {
  customer: string[] | null;
  status: string[] | null;
}

interface Props {
  qbLoading: boolean;
  qbSuccess: { status: boolean; message: string };
  qbError: { status: boolean; message: string };
  alertData: AlertComponentProps;
  handleAlertData: (data: AlertComponentProps) => void;
  summaryData: SummaryDataProps;
  detailData: DetailProps;
  dashboardData: DashboardProps;
  customerInfo: CustomerProps;
  isInvoiceDrawerOpen: boolean;
  invoiceListSelected: InvoiceProps[];
  invoiceSelected: InvoiceProps;
  indexInvoiceSelected: number;
  searchQuery: string;
  selectedRowsToEdit: React.Key[];
  dashboardStatusData: DashboardStatusProps[];
  pdfBuffer: string | null;
  transactionSelected: InvoiceProps;
  onChangeSearchQuery: (value: string) => void;
  handleSelectInvoice: (idx: number) => void;
  getData: ({
    endpoint,
    idClient,
    search,
    filters,
    dates,
    allowLoading,
    next,
  }: {
    endpoint: "summary" | "detail" | "dashboard" | "dashboard-status";
    idClient: string;
    search?: string | null;
    filters?: FilterProps;
    dates?: [string, string, string] | null;
    allowLoading?: boolean;
    next?: (data: any) => void;
  }) => void;
  getCustomerData: (
    customerId: string,
    idClient: string,
    next: () => void
  ) => void;
  getTransactionById: (
    invoiceId: string,
    transactionType: string,
    idClient: string,
    next: () => void
  ) => void;
  sendReminder: ({
    idClient,
    idCustomer,
    DocNumber,
    multiple,
    attachment,
    next,
  }: {
    idClient: string;
    idCustomer: string;
    DocNumber: string[];
    multiple?: boolean;
    attachment?: boolean;
    next?: () => void;
  }) => void;
  getInvoicePdf: ({
    idClient,
    idCustomer,
    DocNumber,
    next,
  }: {
    idClient: string;
    idCustomer: string;
    DocNumber: string;
    next: () => void;
  }) => void;
  handleSelectInvoiceList: (
    type: "client" | "detail" | "age",
    item: any
  ) => void;
  handleEditSelectedInvoice: (name: string, value: any) => void;
  handleCancelSelectInvoiceList: () => void;
  handleRefreshAging: () => void;
  updateSelectedInvoice: (
    idClient: string,
    info: {
      newNote?: string;
      emailAddress?: string;
      phoneNumber?: string;
      newDate?: Dayjs;
    },
    next: () => void
  ) => void;
  onChangeSelectedRowsToEdit: (newSelectedRowToEdit: React.Key[]) => void;
  handleBulkEdit: ({
    endpoint,
    idClient,
    items,
    newStatus,
    next,
  }: {
    idClient: string;
    items: string[];
    endpoint: "invoice-customer" | "invoice";
    newStatus: {
      Status: string | null;
      LastContact: Dayjs | null;
      NewNote: string | null;
    };
    next?: () => void;
  }) => void;
  handleGetQbData: (idClient: string, next: () => void) => void;
  handleChangeFilters: (
    source: "summary" | "detail" | "dashboard",
    idClient: string,
    key: string,
    value: string[]
  ) => void;
  handleRevokeToken: (idClient: string, next: () => void) => void;
  handleLoading: (value: boolean) => void;
  connectLocalToQBCompany: (
    localId: string,
    realmId: string,
    next?: () => void
  ) => void;
  updateQuickbooksData: ({
    endpoint,
    updates,
    next,
  }: {
    endpoint: string;
    updates: any;
    next: () => void;
  }) => void;
  handleCreateClientQuickbooks: (
    idUser: string,
    newClient: ClientProps,
    next: (newId: string) => void
  ) => void;
  onDemandReminder: ({
    idClient,
    customerList,
    next,
  }: {
    idClient: string;
    customerList: string[];
    next?: () => void;
  }) => void;
  onDemandReminderSingle: ({
    idClient,
    docNumberList,
    next,
  }: {
    idClient: string;
    docNumberList: string[];
    next?: () => void;
  }) => void;
}

const QuickbooksContext = React.createContext<Props>({
  qbLoading: false,
  qbSuccess: ALERT_INITIAL_STATE,
  qbError: ALERT_INITIAL_STATE,
  alertData: ALERTCOMPONENT_INITIAL_STATE,
  handleAlertData: () => {},
  summaryData: SUMMARY_DATA_INITIAL_STATE,
  detailData: DETAIL_INITIAL_STATE,
  dashboardData: DASHBOARD_INITIAL_STATE,
  customerInfo: CUSTOMER_INITIAL_STATE,
  isInvoiceDrawerOpen: false,
  invoiceListSelected: [],
  invoiceSelected: INVOICE_INITIAL_STATE,
  indexInvoiceSelected: 0,
  searchQuery: "",
  selectedRowsToEdit: [],
  dashboardStatusData: [],
  pdfBuffer: null,
  transactionSelected: INVOICE_INITIAL_STATE,
  onChangeSearchQuery: () => {},
  handleSelectInvoice: () => {},
  getData: () => {},
  getCustomerData: () => {},
  getTransactionById: () => {},
  sendReminder: () => {},
  getInvoicePdf: () => {},
  handleSelectInvoiceList: () => {},
  handleEditSelectedInvoice: () => {},
  handleCancelSelectInvoiceList: () => {},
  handleRefreshAging: () => {},
  updateSelectedInvoice: () => {},
  onChangeSelectedRowsToEdit: () => {},
  handleBulkEdit: () => {},
  handleGetQbData: () => {},
  handleChangeFilters: () => {},
  handleRevokeToken: () => {},
  handleLoading: () => {},
  connectLocalToQBCompany: () => {},
  updateQuickbooksData: () => {},
  handleCreateClientQuickbooks: () => {},
  onDemandReminder: () => {},
  onDemandReminderSingle: () => {},
});

export default QuickbooksContext;

import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export const AdminPage = () => {
  const navigate = useNavigate();
  const { onLogout, getClientId } = useContext(AuthContext);
  useEffect(() => {
    const clientId = getClientId();
    if (clientId) {
      navigate(`/dashboard/${clientId}`);
    } else {
      onLogout();
    }
  }, []);

  return <></>;
};

import { Modal, Form, Row, Col, Select, DatePicker, Input, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { COLLECTION_INITIAL_STATE } from "../../utils/data";
import dayjs, { Dayjs } from "dayjs";
import DataContext from "../../context/DataContext";
import TextArea from "antd/es/input/TextArea";
import { CollectionProps } from "../../interfaces/interfaces";

interface Props {
  title: string;
  isModalOpen: boolean;
  onCancel: () => void;
  onSubmit: (values: {
    Status: string | null;
    LastContact: Dayjs | null;
    NewNote: string | null;
  }) => void;
}

export const ModalCollectionsBulkUpdate = ({
  title,
  isModalOpen,
  onCancel,
  onSubmit,
}: Props) => {
  const { statusList } = useContext(DataContext);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [form] = Form.useForm();
  const [values, setValues] = useState<CollectionProps>(
    COLLECTION_INITIAL_STATE
  );
  useEffect(() => {
    form.setFieldsValue(COLLECTION_INITIAL_STATE);
    setValues(COLLECTION_INITIAL_STATE);
  }, [form]);

  useEffect(() => {
    if (
      values.Status._id !== "" ||
      values.EmailAddress ||
      values.PhoneNumber ||
      values.NewNote
    ) {
      setIsReadyToSubmit(true);
    } else {
      setIsReadyToSubmit(false);
    }
  }, [values]);

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={values}
        form={form}
        onFinish={() => {
          const newValues = {
            Status:
              values.Status && values.Status._id
                ? values.Status._id
                : typeof values.Status === "string"
                ? values.Status
                : null,
            LastContact: values.LastContact || null,
            NewNote: values.NewNote || null,
          };
          onSubmit(newValues);
          setValues(COLLECTION_INITIAL_STATE);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Status">
              <Select
                value={values.Status}
                onChange={(value) => {
                  setValues((prev) => {
                    return {
                      ...prev,
                      Status: value,
                    };
                  });
                }}
              >
                {statusList.length > 0 ? (
                  statusList.map((option) => (
                    <Select.Option value={option._id} key={option._id}>
                      {option.Label}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value="">{""}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Change">
              <DatePicker
                name="LastContact"
                format={"MM/DD/YYYY"}
                value={values.LastContact && dayjs(values?.LastContact)}
                style={{ width: "100%" }}
                allowClear={false}
                onChange={(date) => {
                  date &&
                    setValues((prev) => {
                      return {
                        ...prev,
                        LastContact: date,
                      };
                    });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Add Note">
              <TextArea
                rows={4}
                placeholder="Notes"
                value={values?.NewNote}
                onChange={(event) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      NewNote: event.target.value,
                    };
                  })
                }
              />
            </Form.Item>
          </Col>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!isReadyToSubmit}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

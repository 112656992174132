import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import DataContext from "../../context/DataContext";
import { Button, Card } from "antd";
import {
  ClientProps,
  QuickbooksCompanyProps,
  UserAccountProps,
} from "../../interfaces/interfaces";
import QuickbooksContext from "../../context/QuickbooksContext";
import AuthContext from "../../context/AuthContext";
import { Loading } from "../../components/ui";

export const AccountMiddleware = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { idUser, realmId } = useParams();
  const { fetchUserAccounts, getQbCompanyInfo, userAccount } =
    useContext(DataContext);
  const {
    connectLocalToQBCompany,
    handleGetQbData,
    handleCreateClientQuickbooks,
    qbLoading,
  } = useContext(QuickbooksContext);
  const { handleRefreshTokenCollections } = useContext(AuthContext);
  const { handleChangeClientSelected, onLogout } = useContext(AuthContext);
  const [newCompany, setNewCompany] = useState<QuickbooksCompanyProps | null>(
    null
  );
  const isExecuting = useRef(false);

  useEffect(() => {
    if (idUser) {
      fetchUserAccounts(idUser);
    } else {
      onLogout(() => navigate("/login"));
    }
  }, [idUser]);

  useEffect(() => {
    let existingConnectedAccount = userAccount.filter(
      (item: UserAccountProps) => item.realmId === realmId
    );
    if (
      existingConnectedAccount.length > 0 &&
      existingConnectedAccount[0]._id
    ) {
      if (searchParams.get("launch")) {
        handleChangeClientSelected(existingConnectedAccount[0]._id);
      } else {
        if (isExecuting.current) return;
        isExecuting.current = true;
        handleGetQbData(existingConnectedAccount[0]._id, () => {
          handleChangeClientSelected(existingConnectedAccount[0]._id);
          isExecuting.current = false;
        });
      }
    }
  }, [userAccount]);

  useEffect(() => {
    if (realmId && userAccount.length > 0) {
      if (
        !userAccount
          .map((item: UserAccountProps) => item.realmId)
          .includes(realmId.toString())
      ) {
        handleCreateCompany(realmId);
      }
    }
  }, [userAccount]);

  const handleCreateCompany = (realmId: string) => {
    getQbCompanyInfo(realmId, (data: QuickbooksCompanyProps) =>
      setNewCompany(data)
    );
  };

  const handleCreateCompanyQuickbooks = () => {
    realmId &&
      idUser &&
      newCompany &&
      handleCreateClientQuickbooks(
        idUser,
        {
          name: newCompany.CompanyName,
          phoneNumber: newCompany.CompanyAddr?.PrimaryPhone?.FreeFormNumber,
          address: newCompany.CompanyAddr?.Line1,
          active: true,
          realmId: realmId,
          quickbooksStatus: { connected: true },
          email: newCompany.CustomerCommunicationAddr?.Email,
        } as ClientProps,
        (newId: string) => {
          if (newId) {
            handleGetQbData(newId, () => {
              handleRefreshTokenCollections();
              handleChangeClientSelected(newId);
            });
          } else {
            onLogout(() => navigate("/login"));
          }
        }
      );
  };

  return (
    <div className="general-view">
      {qbLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div className="general-view">
          {userAccount.length > 0 ? (
            <div className="general-view__component">
              {userAccount.map((item: UserAccountProps, index: number) => (
                <Card
                  className="general-view__component"
                  key={index}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "20px",
                    borderColor: "#17549a",
                  }}
                  bordered
                  actions={[
                    <Button
                      type="primary"
                      disabled={!!item.realmId}
                      onClick={() =>
                        realmId &&
                        connectLocalToQBCompany(item._id, realmId, () =>
                          navigate(0)
                        )
                      }
                    >
                      Connect
                    </Button>,
                  ]}
                  title={item.name}
                >
                  {item.realmId ? (
                    <>This local company is already connected to QuickBooks</>
                  ) : (
                    <>
                      This local company is not connected to Quickbooks, select
                      connect to start syncing
                    </>
                  )}
                </Card>
              ))}
            </div>
          ) : (
            <></>
          )}
          {newCompany && (
            <Card
              style={{
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "20px",
                borderColor: "#17549a",
              }}
              bordered
              title={newCompany.CompanyName}
              actions={[
                <Button type="primary" onClick={handleCreateCompanyQuickbooks}>
                  Create New Company
                </Button>,
                <Button
                  type="default"
                  onClick={() => onLogout(() => navigate("/login"))}
                >
                  Logout
                </Button>,
              ]}
            >
              <>
                Your session has started with a QuickBooks company that is not
                linked to any local company. To begin syncing, click Create New
                Company. Please note that this action will affect your current
                subscription, and a charge for a new company will apply.
                Alternatively, you can log out and connect to a subscribed
                company.
              </>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Select,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import {
  RightOutlined,
  LeftOutlined,
  FileSearchOutlined,
  UserOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { CollectionsForm } from "./CollectionsForm";
import { InvoiceInfo } from "./InvoiceInfo";
import { InvoiceProps, StatusProps } from "../../interfaces/interfaces";
import {
  ALERTCOMPONENT_INITIAL_STATE,
  INVOICE_INITIAL_STATE,
} from "../../utils/data";
import { TabsProps } from "antd/lib";
import { CustomerInfo } from "../customer";
import QuickbooksContext from "../../context/QuickbooksContext";
import { Dayjs } from "dayjs";
import PdfViewer from "../pdf/PdfViewer";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";
import { AlertComponent } from "../ui/AlertComponent";

interface Props {
  title: string;
  open: boolean;
  invoice: InvoiceProps | null;
  options: StatusProps[];
  indexInvoiceSelected: number;
  list?: InvoiceProps[];
  idClient?: string;
  selectInvoice?: (index: number) => void;
  onClickPrevious?: () => void;
  onClickNext?: () => void;
  onClose: () => void;
  onSubmit: (
    newNote?: string,
    emailAddress?: string,
    phoneNumber?: string,
    newDate?: Dayjs
  ) => void;
}

export const InvoiceDrawer = ({
  title,
  open,
  invoice,
  options,
  list,
  idClient,
  indexInvoiceSelected,
  selectInvoice,
  onClickNext,
  onClickPrevious,
  onClose,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const { isDemoEnv, role } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [values, setValues] = useState<InvoiceProps>(INVOICE_INITIAL_STATE);
  const [autoSave, setIsAutoSave] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const {
    getCustomerData,
    getTransactionById,
    getInvoicePdf,
    handleAlertData,
    pdfBuffer,
    qbLoading,
    alertData,
    transactionSelected,
  } = useContext(QuickbooksContext);
  const [isBasicUser, setIsBasicUser] = useState(true);

  useEffect(() => {
    idClient &&
      invoice?.Id &&
      getTransactionById(
        invoice.Id,
        invoice.TransactionType,
        idClient,
        () => {}
      );
  }, [invoice?.Id]);

  useEffect(() => {
    if (transactionSelected) {
      setValues(transactionSelected);
    } else if (invoice) {
      setValues(invoice);
    }
    handleAlertData(ALERTCOMPONENT_INITIAL_STATE);
    return () => {
      setValues(INVOICE_INITIAL_STATE);
    };
  }, [invoice, transactionSelected]);

  useEffect(() => {
    if (values.TransactionType === "Invoice" && !isDemoEnv) {
      idClient &&
        values.DocNumber &&
        getInvoicePdf({
          idClient,
          idCustomer: values.CustomerRef.value,
          DocNumber: values.DocNumber,
          next: () => {},
        });
    }
  }, [values]);

  useEffect(() => {
    idClient && getCustomerData(values?.CustomerRef?.value, idClient, () => {});
  }, [values.CustomerRef?.value]);

  useEffect(() => {
    if (role !== "DEMO") setIsBasicUser(false);
  }, [role]);

  const handleCloseDrawer = () => {
    onClose();
    setIsClosing(false);
    setIsAutoSave(false);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Transactions",
      children: (
        <>
          {invoice && (
            <>
              <CollectionsForm
                values={values}
                form={form}
                options={options}
                onSubmit={(
                  newNote,
                  emailAddress,
                  phoneNumber,
                  newDate,
                  next
                ) => {
                  onSubmit(newNote, emailAddress, phoneNumber, newDate);
                  setIsAutoSave(false);
                  setIsClosing(false);
                  idClient &&
                    getTransactionById(
                      values.Id,
                      values.TransactionType,
                      idClient,
                      () => next && next()
                    );
                }}
                autoSave={autoSave}
                isClosing={isClosing}
                onClose={handleCloseDrawer}
              />
              <Divider />

              <InvoiceInfo
                values={invoice}
                onClickCustomerTab={() => setActiveKey("3")}
              />
            </>
          )}
        </>
      ),
      icon: <FileTextOutlined />,
    },
    {
      key: "3",
      label: "Customer",
      children: <CustomerInfo idClient={idClient} isClosing={isClosing} />,
      icon: <UserOutlined />,
      disabled: qbLoading,
    },
  ];

  if (!isBasicUser) {
    items.splice(1, 0, {
      key: "2",
      label: "Details",
      children: <>{pdfBuffer ? <PdfViewer pdfBuffer={pdfBuffer} /> : <></>}</>,
      icon: <FileSearchOutlined />,
      disabled:
        values.TransactionType !== "Invoice" ||
        !pdfBuffer ||
        qbLoading ||
        isDemoEnv,
    });
  }

  return (
    <>
      <Drawer
        title={title}
        width={720}
        onClose={() => {
          setIsClosing(true);
          setActiveKey("1");
        }}
        maskClosable={false}
        open={open}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setIsAutoSave(true);
          }
        }}
        extra={
          <Space>
            {list && list.length > 1 && (
              <Select
                style={{ width: 200 }}
                value={indexInvoiceSelected}
                onChange={(value) => selectInvoice && selectInvoice(value)}
                options={list.map((item, index) => {
                  return {
                    label: `${item.TransactionType} ${item.DocNumber}`,
                    value: index,
                  };
                })}
              ></Select>
            )}
            <Tooltip title="Previous Record">
              <Button
                disabled={!onClickPrevious}
                icon={<LeftOutlined />}
                onClick={onClickPrevious}
              ></Button>
            </Tooltip>
            <Tooltip title="Next Record">
              <Button
                disabled={!onClickNext}
                icon={<RightOutlined />}
                onClick={onClickNext}
              ></Button>
            </Tooltip>
          </Space>
        }
      >
        {alertData.message && <AlertComponent {...alertData} />}
        <Tabs
          animated
          centered
          items={items}
          defaultActiveKey="1"
          activeKey={activeKey}
          onTabClick={(key) => {
            if (key === "2") {
              if (isDemoEnv) {
                openModalDemo();
                setActiveKey("1");
              } else {
                setActiveKey(key);
              }
            } else {
              setActiveKey(key);
            }
          }}
        />
      </Drawer>
      <DemoModal />
    </>
  );
};

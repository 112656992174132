import { Alert, Button, Card, Popconfirm, Row, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CardComponent,
  CardTitle,
  ColumnComponent,
} from "../../../components/ui";
import Table from "antd/es/table";
import DataContext from "../../../context/DataContext";
import { useParams } from "react-router-dom";
import { INVOICES_WARNING } from "../../../utils/data";
import { transactionColumns } from "../../../utils/colums/transactionColumns";
import AuthContext from "../../../context/AuthContext";
import { useDemoModal } from "../../../hooks";
import { exportTransactionList } from "../../../utils/func/ExportFile";
import { SearchInput } from "../../../components/functional";
import QuickbooksContext from "../../../context/QuickbooksContext";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const DepositClient = () => {
  const { idClient } = useParams();
  const { isDemoEnv, role } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { onChangeSearchQuery } = useContext(QuickbooksContext);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const hasBeenWarned = useRef(false);
  const {
    getData,
    invoiceList,
    handleBulkDeleteData,
    dataLoading,
    handleWarning,
    dataWarning,
  } = useContext(DataContext);

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: 100,
        total: invoiceList.length,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, [invoiceList]);

  useEffect(() => {
    if (idClient) {
      getData("deposit", idClient);
    }
  }, [idClient]);

  useEffect(() => {
    onChangeSearchQuery("");
    if (
      !dataWarning.status &&
      !hasBeenWarned.current &&
      role !== "DEMO" &&
      role !== "READ" &&
      role
    ) {
      handleWarning(
        true,
        INVOICES_WARNING,
        undefined,
        () => (hasBeenWarned.current = true)
      );
      hasBeenWarned.current = true;
    }
  }, [role]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="general-view">
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title="Deposits" />
        </ColumnComponent>
        <ColumnComponent>
          <CardComponent
            title="Deposits List"
            className="summary-client__card"
            extra={[
              <SearchInput
                onSearch={(value: string) => {
                  if (value) {
                    idClient && getData("deposit", idClient, value);
                  } else {
                    idClient && getData("deposit", idClient);
                  }
                }}
              />,
            ]}
            actions={[
              <></>,
              <>
                <span style={{ marginLeft: 8, marginRight: 8 }}>
                  {hasSelected
                    ? `${selectedRowKeys.length} items selected  of ${invoiceList.length}`
                    : ""}
                </span>
                <Tooltip title="Export Deposits" placement="bottom">
                  <Button
                    type="default"
                    onClick={() =>
                      exportTransactionList(
                        invoiceList,
                        "Deposit",
                        selectedRowKeys as string[]
                      )
                    }
                  >
                    Export
                  </Button>
                </Tooltip>
              </>,
              <>
                {role !== "READ" && role !== "DEMO" ? (
                  <>
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => {
                        if (isDemoEnv) {
                          openModalDemo();
                        } else {
                          if (idClient) {
                            handleBulkDeleteData(
                              "deposit",
                              idClient,
                              selectedRowKeys as string[]
                            );
                            setSelectedRowKeys([]);
                          }
                        }
                      }}
                    >
                      <Tooltip
                        title="Delete Selected Deposits"
                        placement="bottom"
                      >
                        <Button
                          type="primary"
                          disabled={!hasSelected}
                          style={{ marginLeft: 16 }}
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </>
                ) : (
                  <></>
                )}
              </>,
            ]}
          ></CardComponent>
        </ColumnComponent>
        <ColumnComponent>
          <Table
            bordered
            loading={dataLoading}
            columns={transactionColumns({
              role,
              action: (record) => {
                if (isDemoEnv) {
                  openModalDemo();
                } else {
                  record.DocNumber &&
                    idClient &&
                    handleBulkDeleteData("deposit", idClient, [
                      record.DocNumber,
                    ]);
                }
              },
            })}
            dataSource={invoiceList}
            size="small"
            rowKey={"Id"}
            rowSelection={rowSelection}
            scroll={{ y: "70vh", x: "100%" }}
            tableLayout="auto"
            pagination={tableParams.pagination}
          />
        </ColumnComponent>
      </Row>
      <DemoModal />
    </div>
  );
};

import React from "react";
import { Result, Row } from "antd";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { Link } from "react-router-dom";

const supportPage = `https://support.quattroapps.app/?token=${localStorage.getItem(
  "info"
)}`;

export const HelpPage = () => {
  return (
    <div className="general-view">
      <Row gutter={[24, 0]} align="top" justify="center">
        <ColumnComponent>
          <CardTitle title="Need some help?" />
          <Result
            title="Please reach out to our Support team"
            extra={
              <Link to={supportPage} target="_blank">
                Open Support Page
              </Link>
            }
          />
        </ColumnComponent>
      </Row>
    </div>
  );
};

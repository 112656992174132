import React, { useContext, useEffect, useRef } from "react";
import { Loading } from "../../components/ui";
import AuthContext from "../../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

export const LaunchMiddleware = () => {
  const { handleRefreshTokenCollections, compareToken } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isRunning = useRef(false);

  useEffect(() => {
    if (isRunning.current) return;
    isRunning.current = true;
    const token = searchParams.get("token");
    const currentToken = localStorage.getItem("info");
    if (!currentToken) {
      navigate(`/login?launch=true&token=${token}`);
      isRunning.current = false;
    } else {
      if (token) {
        const isSameUser = compareToken(token);
        if (!isSameUser) {
          localStorage.removeItem("info");
          navigate(`/login?launch=true&token=${token}`);
        } else {
          handleRefreshTokenCollections(() => navigate(`/dashboard`));
        }
      } else {
        handleRefreshTokenCollections(() => navigate(`/dashboard`));
      }
      isRunning.current = false;
    }
  }, []);

  return <Loading />;
};

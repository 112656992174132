import { Alert } from "antd";
import React, { useContext } from "react";
import { AlertComponentProps } from "../../interfaces/interfaces";

const styles = {
  fixedAlert: {
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
  },
};

export const AlertComponent = ({
  message,
  description,
  type,
  onClose,
}: AlertComponentProps) => {
  return (
    <div style={{ ...styles.fixedAlert, position: "fixed" }}>
      <Alert
        className="general-view"
        message={message}
        description={description}
        type={type}
        showIcon
        closable
        onClose={onClose}
      />
    </div>
  );
};
